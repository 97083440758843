/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />

$(document).ready(() => {
    EditAddress.initAll();
});

$(document).ajaxComplete((event, xhr, settings) => {
    if (!settings.dataTypes.some(t => t === "json")) {
        $(xhr.responseText).find('.address-editor, .address-form').each((_i, addressContainerElement: Element) => {
            let childElementId = $(addressContainerElement).find('.customer-number')[0].id;
            let addressContainer = $(`#${childElementId}`).parents('.address-editor, .address-form').first();
            if (addressContainer != null) {
                EditAddress.init(addressContainer);
            }
        });
    }
});

class EditAddress {
    public static initAll() {
        $('.address-editor, .address-form').each((_i, addressContainerElement: Element) => {
            this.init($(addressContainerElement));
        });
    }

    public static init(addressContainer: JQuery) {
        addressContainer.find('.postal-address-country-code').on("change", e => {
            this.onCountryCodeChange($(e.target));
        });
        addressContainer.find('.postal-address-state-code').on("change", e => {
            this.onStateCodeChange($(e.target));
        });
        addressContainer.find('.shipping-to-selector-option').on("change",
            e => {
                this.changeAddressMode($(e.target), addressContainer);
            });

        // Add 3-character ISO code attribute to each country
        this.getCountries(addressContainer).forEach(c => {
            var option = $('.postal-address-country-code option[value="' + c.Value + '"]');
            $(option).attr("isoAlpha3", c.IsoAlpha3);
        });

        // Trigger a change event to get things set up properly with the initial country value
        addressContainer.find('.postal-address-country-code').trigger('change');

        var shipToSelector = addressContainer.find('.shipping-to-selector-option:checked:first');
        this.changeAddressMode(shipToSelector, addressContainer);
    }

    private static getCountries(addressContainer: JQuery) {
        return addressContainer.find('.postal-address-country-code').data('countries') == null ?
            [] :
            [addressContainer.find('.postal-address-country-code').data('countries')][0]
    }

    private static onCountryCodeChange(countryInput: JQuery) {
        var selectedCountryOption = countryInput.find(':selected');
        var selectedCountryCode = selectedCountryOption.val();

        var addressFormParent = countryInput.parents('.address-form');
        var postalInput = addressFormParent.find('.postal-address-postal-code');
        var postalDiv = postalInput.parents('.horizontal-form-group:first');
        var stateInput = addressFormParent.find('.postal-address-state-code');
        var stateDiv = stateInput.parents('.horizontal-form-group:first');
        var initialStateCode = stateInput.data('initial-state-code');
        var countyInput = addressFormParent.find('.postal-address-county');
        var countyDiv = countyInput.parents('.horizontal-form-group:first');
        var initialCounty = countyInput.data('initial-county');

        var country = this.getCountries(addressFormParent).filter(c => c.Value === selectedCountryCode)[0];

        // Uses the same postal code show/hide logic as ShoppingCart\Services\AddressValidationService.cs
	    if (!country || country.PostalCodeMask === null) {
            postalInput.prop('disabled', true);
            postalDiv.addClass("hidden");
        } else {
            postalInput.prop('disabled', false);
            postalInput.attr('maxlength', country.PostalCodeMask.length);
            postalDiv.removeClass("hidden");
        }

        // If the country allows states, display the states dropdown list.
        this.populateDropDownList(!country ? null : country.States,
            stateInput, stateDiv, initialStateCode, stateInput.data('placeholder'));

        // Populate the counties dropdown list based on whether the country allows counties and/or states
        // and whether the user has already selected a state.
        if (stateInput.val()) {
            this.populateCountiesByState(country, stateInput.val(), countyInput, countyDiv, initialCounty);
        }
        else {
            this.populateCountiesByCountry(country, countyInput, countyDiv, initialCounty);
        }
    }

    private static onStateCodeChange(stateInput: JQuery) {
        var selectedStateOption = stateInput.find(':selected');
        var selectedStateCode = selectedStateOption.val();

        var addressFormParent = stateInput.parents('.address-form');
        var selectedCountryCode = addressFormParent.find('.postal-address-country-code').val();
        var countyInput = addressFormParent.find('.postal-address-county');
        var countyDiv = countyInput.parents('.horizontal-form-group:first');
        var initialCounty = countyInput.data('initial-county');

		var country = this.getCountries(addressFormParent).filter(c => c.Value === selectedCountryCode)[0];

        // Populate the counties dropdown list based on whether the country allows counties and the selected a state.
        this.populateCountiesByState(country, selectedStateCode, countyInput, countyDiv, initialCounty);
    }

	private static changeAddressMode(selector: JQuery, addressContainer: JQuery) {
        var addressTypesDataKey = "address-types-key";

        if (selector != null && selector.length > 0 && selector.data(addressTypesDataKey)) {
            let addressCodeInput = addressContainer.find('select.address-code');
            if (addressCodeInput.length > 0) {
                let addressTypes: any[] = addressCodeInput.data(selector.data(addressTypesDataKey));
                addressCodeInput.empty();
                if (addressTypes != null) {
                    addressTypes.forEach((item) => {
                        let option = `<option value="${item.Value}" ${item.Selected ? 'selected' : ''}">${item.Text}</option>`;
                        addressCodeInput.append(option);
                    });
                }
            }
        }
    }

    private static populateDropDownList(items: any, inputElement: JQuery, divElement: JQuery, initialValue: any, selectText: string) {
        inputElement.html('<option value="">' + selectText + '</option>');
        if (items && items.length > 0) {
            $.each(items,
                (j, item) => {
                    inputElement.append(new Option(item.Text,
                        item.Value,
                        item.Value === initialValue,
                        item.Value === initialValue));
                });
            if (inputElement.val() !== '') {
                inputElement.children().first().remove();
            }
            let selectElement = divElement.find('select');
            selectElement.prop('required', true);
            divElement.removeClass("hidden");
            selectElement.prop('disabled', false);
        } else {
            let selectElement = divElement.find('select');
            selectElement.prop('required', false);
            divElement.addClass("hidden");
            selectElement.prop('disabled', true);
        }
    }

    // If the country allows states and counties, display a dropdown list of counties filtered by the state.
    private static populateCountiesByState(country: any, stateCode: string, inputElement: JQuery, divElement: JQuery, initialValue: any) {
        this.populateDropDownList((country && country.Counties && country.States) ? country.Counties.filter(c => c.State === stateCode) : null,
            inputElement, divElement, initialValue, inputElement.data('placeholder'));
    }

    // If the country allows only counties, display a dropdown list of counties for the country.
    private static populateCountiesByCountry(country: any, inputElement: JQuery, divElement: JQuery, initialValue: any) {
        this.populateDropDownList((country && country.Counties && !country.States) ? country.Counties : null,
            inputElement, divElement, initialValue, inputElement.data('placeholder'));
    }
}
